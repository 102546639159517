<template>
  <div>
    <h1>女優検索</h1>
    <ul>
      <li
        v-for="actor in actorTags"
        :key="actor.id"
        @click="goToVideoPage(actor.id)"
        style="cursor: pointer;"
      >
        {{ actor.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import actorTags from "@/assets/tags/actressTags.json";

export default {
  data() {
    return {
      actorTags, // JSON ファイルのデータをそのまま使う
    };
  },
  methods: {
    goToVideoPage(actressId) {
      this.$router
      .push({ path: "/video", query: { actressId } })
      .then(() => {
          window.location.reload(); 
      })
      .catch((err) => {
        console.error(err);
      });
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
}
li {
  margin: 5px 0;
}
</style>
<template>
  <div>
    <div class="button-container">
      <button 
        @click="search" 
        :disabled="selectedTags.length === 0"
      >
        検索
      </button>
    </div>
    <div v-for="category in tags" :key="category.category" class="category-container">
      <h2>{{ category.category }}</h2>
      <div class="tag-container">
        <div v-for="tag in category.tags" :key="tag.genre_id">
          <span 
            :class="{ 'selected-tag': isSelected(tag.genre_id), 'tag': true }" 
            @click="toggleTag(tag.genre_id)"
          >
            {{ tag.genre_name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* General styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}

/* Heading styling */
h2 {
  margin: 15px 0;
  font-size: 1.2rem;
  color: #333;
}

/* Tag container styling */
.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px; /* Adjusted spacing between tags */
  margin-bottom: 20px; /* Space between categories */
  padding: 10px; /* Added padding for better spacing */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/* Tag styling */
.tag, .selected-tag {
  display: inline-block;
  padding: 8px 12px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.tag {
  background-color: #f1f3f5;
  color: #555;
}

.selected-tag {
  background-color: #ffcc00;
  color: #000;
  font-weight: bold;
}

/* Button container styling */
.button-container {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 1000;
}

/* Button styling */
button {
  padding: 12px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 25px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:active {
  transform: scale(0.98);
}
</style>

<script>
import searchableTags from "@/assets/tags/searchableTags.json";

export default {
  data() {
    return {
      tags: searchableTags["searchableTags"],
      selectedTags: [],
    };
  },
  methods: {
    toggleTag(tagId) {
      if (this.isSelected(tagId)) {
        this.selectedTags = this.selectedTags.filter((id) => id !== tagId);
      } else {
        this.selectedTags.push(tagId);
      }
    },
    isSelected(tagId) {
      return this.selectedTags.includes(tagId);
    },
    search() {
      const param = this.selectedTags.join("-");
      this.$router
        .push({ path: "/video", query: { tags: param } })
        .then(() => {
          window.location.reload(); // Refresh the page
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
<template>
  <div class="content-grid">
    <div v-for="video in videos" :key="video.contentId" class="content-item">
      <router-link :to="{ path: '/video', query: { contentId: video.contentId } }">
        <img :src="video.imageURL" :alt="video.title" />
      </router-link>
      <h3>{{ video.title }}</h3>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import datafetcher from '@/scripts/datafetcher';

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      videos: []
    };
  },
  async created() {
    try {
      const res = await datafetcher.get(`/searchVideo?genreId=2001`);
      this.videos = res.data.items.map(item => ({
        title: item.title,
        affiliateURL: item.affiliateURL,
        imageURL: item.imageURL.large,
        contentId: item.contentId
      }));
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  }
});
</script>

<style scoped>
.content-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 16px;
}

/* 3 columns for screens 700px or wider */
@media (min-width: 700px) {
  .content-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* 4 columns for screens 1100px or wider */
@media (min-width: 1100px) {
  .content-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.content-item {
  text-align: center;
  max-height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-item img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  margin-top: 8px;
  font-size: 1em;
  color: #333;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}


</style>
